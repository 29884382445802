import { StandardIconType } from "@wind/common-interfaces/icons";

export interface Feature {
  title: string;
  icon: StandardIconType;
  video: string;
  durationMs: number;
  description?: string;
}

export const FEATURES: Feature[] = [
  {
    title: "Weekly Accomplishments",
    icon: StandardIconType.ACCOMPLISHMENTS,
    video:
      "https://windmill-storage-prod.s3.us-east-1.amazonaws.com/marketing/videos/accomplishments-safe.mp4",
    description:
      "Windy gathers weekly accomplishments to ensure your team stays aligned and productive",
    durationMs: 14000,
  },
  {
    title: "Realtime Feedback",
    icon: StandardIconType.FEEDBACK_GIVEN,
    video:
      "https://windmill-storage-prod.s3.us-east-1.amazonaws.com/marketing/videos/feedback-flow.mp4",
    description: "Windy sees who's collaborating and automatically collects feedback from them",
    durationMs: 15000,
  },
  {
    title: "Stats",
    icon: StandardIconType.DEVICE_DESKTOP_ANALYTICS,
    video: "https://windmill-storage-prod.s3.us-east-1.amazonaws.com/marketing/videos/stats.mp4",
    description: "Quickly visualize key performance metrics and trends",
    durationMs: 17000,
  },
  {
    title: "Deep Dives",
    icon: StandardIconType.DEEP_DIVE,
    video:
      "https://windmill-storage-prod.s3.us-east-1.amazonaws.com/marketing/videos/deep-dive.mp4",
    description: "Windy generates detailed reports to help you quickly understand team insights",
    durationMs: 13000,
  },
  {
    title: "Employee Profiles",
    icon: StandardIconType.USER_CIRCLE,
    video: "https://windmill-storage-prod.s3.us-east-1.amazonaws.com/marketing/videos/profile.mp4",
    description: "Instantly access profiles for every employee on your team",
    durationMs: 12000,
  },
];
